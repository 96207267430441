import React from "react";
import DefaultBlockContent from "../sanity/defaultBlockContent";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const Hero = (props) => {
  const BackgroundImage = styled.div`
    background-image: url(${props?.illustration?.image?.asset?.url});
    background-position: center 0;
    background-repeat: no-repeat;
    margin: auto;
    height: 35vw;
    background-size: 100% auto;
    position: relative;
    max-height: 50rem;
  `;
  const ImageContainer = () => {
    return <BackgroundImage props={props}></BackgroundImage>;
  };
  return (
    <>
      <Container fluid className="p-0 bgImgWrapper">
        {props?.illustration?.image?.asset &&
          !props?.illustration?.disabled && <ImageContainer />}
      </Container>

      {props.tagline && (
        <Container>
          <div className="jumbotronWrapper 01">
            <div className="jumbotron jumbotron-fluid">
              <DefaultBlockContent blocks={props.tagline} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default Hero;
