import React from "react";
import { Link } from "gatsby";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "../../components/sanity/imageBuilder";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../sanity/sanityClient";

// Få inn optional title

const LinkBoxLister = ({ pageData }) => {
  const LinkChecker = ({ props }) => {
    var isNotParentPageAndHasChild =
      props?.slug?.current != props?.navMenu?.parentPage?.slug?.current;

      if(props?.navMenu === undefined){
        isNotParentPageAndHasChild = false;
      }
    
    if (isNotParentPageAndHasChild) {
      return (
        <Link
          className="linkBoxes"
          to={`/${props.navMenu.parentPage.slug.current}/${props.slug.current}`}
          // to="/"
        >
          {props.title}
        </Link>
      );
    } else {
      return (
        <Link className="linkBoxes" to={`/${props.slug.current}`}>
          {props.title}
        </Link>
      );
    }
  };
  return (
    <>
      {pageData.map((linkedPages) => {
        return (
          <Col
            key={linkedPages._id}
            xs={12}
            md={6}
            lg={6}
            className="col-lg-4 d-flex align-items-stretch"
          >
            <Card>
              <div className="card-section">
                {linkedPages?.illustration?.image && (
                  <Image props={linkedPages.illustration.image} />
                )}
                {/* <Image props={linkedPages.mainImage} width="462" height="231" /> */}
                <Card.Body>
                  <h2 className="card-title h3 ">
                    {linkedPages.slug && <LinkChecker props={linkedPages} />}
                  </h2>
                  {linkedPages?.pageIntro && (
                    <Card.Text>{linkedPages.pageIntro}</Card.Text>
                  )}
                </Card.Body>
              </div>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default LinkBoxLister;
