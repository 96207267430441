const minLength = (props) => {
    if (props && props.length && props.length.minimum) {
        return (
            props.length.minimum
        )
    } else {
        return (
            ""
        )
    }
}
export default minLength