import React from "react";
import DefaultBlockContent from "../sanity/defaultBlockContent";
import { Container } from "react-bootstrap";

const BlockContentRenderer = (props) => {
  return (
    <Container>
      <DefaultBlockContent blocks={props.content} />
    </Container>
  );
};
export default BlockContentRenderer;
