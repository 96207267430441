import React from "react";
import { Link } from "gatsby";

const LinkChecker = ({ props }) => {
  // Local page route
  const isNotParentPageAndHasChild =
    props.landingPageRoute?.navMenu?.parentPage?.slug?.current !==
    props.landingPageRoute.slug.current;
  if (props && props.landingPageRoute) {
    if (isNotParentPageAndHasChild) {
      return (
        <li>
          <Link
            to={`/${props.landingPageRoute.navMenu.parentPage.slug.current}/${props.landingPageRoute.slug.current}`}
          >
            {props.title}
          </Link>
        </li>
      );
    } else {
      return (
        <li>
          <Link to={`/${props.landingPageRoute.slug.current}`}>
            {props.title}
          </Link>
        </li>
      );
    }
  }
  // Manually written path route (Example /blog)
  else if (props && props.route) {
    return (
      <li>
        <Link to={props.route}>{props.title}</Link>
      </li>
    );
  }
  // External links
  else if (props && props.link) {
    return (
      <li>
        <a href={props.link}>{props.title}</a>
      </li>
    );
  } else {
    // Some links may only have a title but no target, in these cases we return nothing
    return <></>;
  }
};

export default LinkChecker;
