import React from "react";
import minLength from "./minLength";
import maxLength from "./maxLength";
import required from "./required";
import ErrorMessages from "./errorMessages";
import { Form } from "react-bootstrap";
const camelCase = require("camelcase");

const InputField = ({ props, register, errors, type }) => {
  const name = camelCase(props.name);

  return (
    <>
      <ErrorMessages error={errors[name]} props={props} />
      <Form.Label htmlFor={camelCase(props.name)}>{props.label}</Form.Label>

      <Form.Control
        type={type}
        inputRef={register}
        id={name}
        aria-invalid={errors[name] ? "true" : "false"}
        name={name}
        ref={register({
          required: required(props),
          maxLength: maxLength(props),
          minLength: minLength(props),
        })}
        minLength={minLength(props)}
        maxLength={maxLength(props)}
      />
    </>
  );
};

export default InputField;
