import React from "react";
import { Container } from "react-bootstrap";
import LinkChecker from "../LinkChecker";

const LinkLister = (props) => {
  return (
    <Container>
      <ul className="no-bullet link-list">
        {props &&
          props.links &&
          props.links.map((link) => (
            <LinkChecker props={link} key={link._key} />
          ))}
      </ul>
    </Container>
  );
};

export default LinkLister;
