import BaseBlockContent from "@sanity/block-content-to-react";
import React from "react";
import Figure from "./figure";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { Link } from "gatsby";
const isParentPage = (currentLink, props) => {
  if (props.parentPage === props.currentPage || props?.link || props?.route) {
    return `/${currentLink}`;
  } else {
    return `/${props.parentPage}/${currentLink}`;
  }
};

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>;
        case "h2":
          return <h2>{props.children}</h2>;
        case "h3":
          return <h3>{props.children}</h3>;
        case "h4":
          return <h4>{props.children}</h4>;
        case "blockquote":
          return <blockquote>{props.children}</blockquote>;
        default:
          return <p>{props.children}</p>;
      }
    },
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);
      return <YouTube videoId={id} />;
    },
    figure(props) {
      return <Figure image={props.node} />;
    },
  },
  marks: {
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${isParentPage(slug.current)}`;
      return <Link className="underline" to={href}>{children}</Link>;
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener" className="underline">
          {children}
        </a>
      ) : (
        <a href={href} className="underline">{children}</a>
      );
    },
  },
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
);
export default BlockContent;
