import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

const Partners = ({ partners }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityPartners {
        edges {
          node {
            id
            partners {
              name
              _key
              partnerDescription
              partnerLogo {
                alt
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const AllPartners = () => {
    const allPartners = data.allSanityPartners.edges[0].node.partners;

    const SinglePartner = ({ partner }) => {
      return (
        <Col sm="6" md="3" lg="4" className="logoScaling">
          <img
            src={partner.partnerLogo.asset.url}
            alt={partner.partnerLogo.alt}
            className="partnerLogos"
          />
        </Col>
      );
    };
    const PartnerLooper = allPartners.map((partner) => {
      return <SinglePartner partner={partner} key={partner._key} />;
    });
    return PartnerLooper;
  };

  return (
    <div className="pt-8 pt-md-10 partnerPadding partners" role="region">
      <div className="container ">
        <section aria-label="Partners">
          <Row className="justify-content-md-center">
            <AllPartners />
          </Row>
        </section>
      </div>
    </div>
  );
};

export default Partners;
