const maxLength = (props) => {
    if (props && props.length && props.length.maximum) {
        return (
            props.length.maximum
        )
    } else {
        return (
            ""
        )
    }
}

export default maxLength